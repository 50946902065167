import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileKey2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxMFY0YTIgMiAwIDAgMSAyLTJoOC41TDIwIDcuNVYyMGEyIDIgMCAwIDEtMiAySDQiIC8+CiAgPHBvbHlsaW5lIHBvaW50cz0iMTQgMiAxNCA4IDIwIDgiIC8+CiAgPGNpcmNsZSBjeD0iNCIgY3k9IjE2IiByPSIyIiAvPgogIDxwYXRoIGQ9Im0xMCAxMC00LjUgNC41IiAvPgogIDxwYXRoIGQ9Im05IDExIDEgMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-key-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileKey2 = createLucideIcon('FileKey2', [
  ['path', { d: 'M4 10V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H4', key: '1nw5t3' }],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '4', cy: '16', r: '2', key: '1ehqvc' }],
  ['path', { d: 'm10 10-4.5 4.5', key: '7fwrp6' }],
  ['path', { d: 'm9 11 1 1', key: 'wa6s5q' }],
]);

export default FileKey2;
